import React from "react";
import { Link } from "gatsby";
import { MWDStoreLink } from "./storeLinks";

const Header = () => (
  <div className="header">
    <div className="header-container">
      <Link to="/" className="header-home">
        Modern Web Designer
      </Link>
      <p className="description">
        Create elegant, modern web designs in minutes on your iPhone or iPad{" "}
        <a href="https://complexview.com">(from James Porter)</a>.
      </p>

      <MWDStoreLink />

      <div className="header-links">
        <Link to="/">The App</Link>
        <Link to="/colour-picker">The Colour Picker</Link>
        <Link to="/links">Resources</Link>
      </div>
    </div>
  </div>
);

export default Header;
