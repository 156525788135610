import React from "react";
import { Link } from "gatsby";

const Footer = () => (
  <div className="footer">
    <div className="footer-outer">
      <div className="footer-links">
        <h2>Me, elsewhere</h2>
        <div className="footer-link-container">
          <a href="https://twitter.com/complexview">Twitter</a>
          <a href="https://github.com/jamesporter">Github</a>
          <a href="https://www.linkedin.com/profile/view?id=39625869">
            LinkedIn
          </a>
        </div>
      </div>
      <div className="footer-links">
        <h2>My other sites</h2>
        <div className="footer-link-container">
          <a href="https://www.amimetic.co.uk">Other Projects</a>
          <a href="https://complexview.com">Complex View (My main site)</a>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
