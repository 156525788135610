import { types, getParent, clone, getSnapshot } from "mobx-state-tree";
import Color from "color";
import { StoreType } from "./Store";

const Colour = types
  .model("Colour", {
    hue: types.integer,
    saturation: types.integer,
    brightness: types.integer,
    name: types.string
  })
  .views(self => ({
    get rgba() {
      return Color.hsl(self.hue, self.saturation, self.brightness).hex();
    },
    get hsv() {
      return Color.hsl(self.hue, self.saturation, self.brightness).toString();
    }
  }))
  .actions(self => ({
    setHSL(h: number, s: number, l: number) {
      self.hue = h;
      self.saturation = s;
      self.brightness = l;
    },
    setHue(h: number) {
      self.hue = h;
    },
    setHueFromEvent(evt: React.ChangeEvent<HTMLInputElement>) {
      try {
        const hue = parseInt(evt.target.value);
        self.hue = hue;
        return;
      } catch (ex) {
        console.log(ex);
        self.hue = 0;
      }
    },
    incrementHue() {
      if (self.hue < 360) self.hue += 1;
    },
    decrementHue() {
      if (self.hue > 0) self.hue -= 1;
    },
    setSaturation(h: number) {
      self.saturation = h;
    },
    setSaturationFromEvent(evt: React.ChangeEvent<HTMLInputElement>) {
      try {
        const saturation = parseInt(evt.target.value);
        self.saturation = saturation;
        return;
      } catch (ex) {
        console.log(ex);
        self.saturation = 0;
      }
    },
    incrementSaturation() {
      if (self.saturation < 100) self.saturation += 1;
    },
    decrementSaturation() {
      if (self.saturation > 0) self.saturation -= 1;
    },
    setBrightness(h: number) {
      self.brightness = h;
    },
    setBrightnessFromEvent(evt: React.ChangeEvent<HTMLInputElement>) {
      try {
        const brightness = parseInt(evt.target.value);
        self.brightness = brightness;
        return;
      } catch (ex) {
        console.log(ex);
        self.brightness = 0;
      }
    },
    incrementBrightness() {
      if (self.brightness < 100) self.brightness += 1;
    },
    decrementBrightness() {
      if (self.brightness > 0) self.brightness -= 1;
    },
    setName(evt: React.ChangeEvent<HTMLInputElement>) {
      self.name = evt.target.value;
    },
    setNameTo(name: string) {
      self.name = name;
    },
    remove() {
      const parent: StoreType = getParent(self, 2);
      parent.removeColour(self);
    },
    copy() {
      const parent: StoreType = getParent(self, 2);
      parent.copyColour(getSnapshot(self));
    }
  }));

export type ColourType = ReturnType<typeof Colour.create>;
export default Colour;
