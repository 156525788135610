import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Helmet from "react-helmet";

import Header from "../header";
import Footer from "../footer";

import GatsbyConfig from "../../../gatsby-config";

import "./index.styl";

const TemplateWrapper = ({ children }: { children: React.ReactNode }) => (
  <div>
    <Helmet
      title="Modern Web Designer (from James Porter)"
      meta={[
        {
          name: "description",
          content:
            "Create lightweight, fast Web designs on your iPad or iPhone in minutes"
        }
      ]}
    />
    <Header />

    <div className="content">
      <div className="main-content">{children}</div>
    </div>

    <Footer />
  </div>
);

export default TemplateWrapper;
