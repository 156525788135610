import { types, destroy } from "mobx-state-tree";
import Colour from "./Colour";
import { asJson, asStylus } from "../util/output";

export type OutputFormat = "JSON" | "Stylus";
export const outputFormats: OutputFormat[] = ["JSON", "Stylus"];

const Store = types
  .model("ColorState", {
    format: types.string,
    colours: types.array(Colour)
  })
  .views(self => ({
    get output(): string {
      switch (self.format as OutputFormat) {
        case "JSON":
          return asJson(self as StoreType);
        case "Stylus":
          return asStylus(self as StoreType);
        default:
          return "Invalid format";
      }
    }
  }))
  .actions(self => ({
    addColour() {
      // @ts-ignore I don't think it can do this?
      self.colours.push({
        hue: 0,
        saturation: 80,
        brightness: 70,
        name: ""
      });
    },
    copyColour(colour) {
      // @ts-ignore I don't think it can do this?
      const c = Colour.create(colour);
      c.setNameTo(c.name + "2");
      self.colours.push(c);
    },
    removeColour(item: any) {
      destroy(item);
    },
    setFormat(format: string) {
      self.format = format;
    }
  }));

export type StoreType = ReturnType<typeof Store.create>;

export default Store;
