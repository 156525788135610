import { StoreType } from "../state/Store";

export const asJson = (store: StoreType): string => {
  const output: { [key: string]: string } = {};
  let i = 1;

  store.colours.forEach(c => {
    let name;
    const normalisedName = c.name.replace(/[^A-z0-9]/g, "");
    if (normalisedName.length > 0) {
      name = normalisedName;
    } else {
      name = `colour${i}`;
      i++;
    }
    output[name] = c.rgba;
  });

  return JSON.stringify(output, null, 2);
};

export const asStylus = (store: StoreType): string => {
  let output = "";
  let i = 1;
  store.colours.forEach(c => {
    let name;
    const normalisedName = c.name.replace(/[^A-z0-9]/g, "");
    if (normalisedName.length > 0) {
      name = normalisedName;
    } else {
      name = `colour${i}`;
      i++;
    }
    output += `${name} = ${c.rgba}\n`;
  });

  return output;
};
