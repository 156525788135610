import * as React from "react";
import { observer } from "mobx-react";
import { StoreType } from "./state/Store";
import ColourChoice from "./ColourChoice";

export interface ColourListProps {
  store: StoreType;
}

export default observer(
  class ColourListComponent extends React.Component<ColourListProps, any> {
    constructor(props: ColourListProps) {
      super(props);
    }

    public render() {
      const { store } = this.props;
      return (
        <div>
          {store.colours.map((c, i) => (
            <ColourChoice colour={c} key={i} index={i} />
          ))}
          <a onClick={store.addColour} className="button-link">
            Add Colour
          </a>
        </div>
      );
    }
  }
);
