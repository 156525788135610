import * as React from "react";
import { ColourType } from "./state/Colour";
import { observer } from "mobx-react";
import Suggestions from "./Suggestions";

interface ColourChoiceProps {
  colour: ColourType;
  index: number;
}

const ColourChoice: React.SFC<ColourChoiceProps> = ({ colour, index }) => {
  return (
    <div className="colour-choice">
      <div
        className="swatch"
        style={{
          backgroundColor: colour.rgba
        }}
      />
      <div className="column">
        <input
          className="colour-name-entry"
          type="text"
          value={colour.name}
          onChange={colour.setName}
          placeholder="Colour Name"
        />
        <div className="pickers">
          <div className="picker">
            <h5>Hue</h5>
            <a className="adjust-button" onClick={colour.decrementHue}>
              -
            </a>
            <input
              type="number"
              value={colour.hue}
              onChange={colour.setHueFromEvent}
              className="number-input"
            />
            <a className="adjust-button" onClick={colour.incrementHue}>
              +
            </a>
          </div>
          <div className="picker">
            <h5>Saturation</h5>
            <a className="adjust-button" onClick={colour.decrementSaturation}>
              -
            </a>
            <input
              type="number"
              value={colour.saturation}
              onChange={colour.setSaturationFromEvent}
              className="number-input"
            />
            <a className="adjust-button" onClick={colour.incrementSaturation}>
              +
            </a>
          </div>
          <div className="picker">
            <h5>Lightness</h5>
            <a className="adjust-button" onClick={colour.decrementBrightness}>
              -
            </a>
            <input
              type="number"
              value={colour.brightness}
              onChange={colour.setBrightnessFromEvent}
              className="number-input"
            />
            <a className="adjust-button" onClick={colour.incrementBrightness}>
              +
            </a>
          </div>
        </div>
        <h6 style={{ paddingLeft: 10, paddingTop: 10 }}>Suggestions</h6>
        <Suggestions colour={colour} />
        <div className="info">
          <div>{colour.hsv}</div>
          <div>{colour.rgba}</div>
          <a onClick={colour.copy} className="button-link small">
            Copy
          </a>
          {index > 2 && (
            <a onClick={colour.remove} className="button-link small">
              X
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ColourChoice);
