import React from "react";
import ColourPicker from "../components/picker/ColourPicker";
import Layout from "../components/layout";

export default () => (
  <Layout>
    <div className="container">
      <h4>Colour Picker (Preview Release)</h4>
      <ColourPicker />
    </div>
  </Layout>
);
