import * as React from "react";
import Store, { StoreType } from "./state/Store";
import ColourList from "./ColourList";
import Output from "./Output";

export interface ColourPickerProps {
  store: StoreType;
}

const store = Store.create({
  format: "JSON",
  colours: [
    {
      hue: 20,
      saturation: 80,
      brightness: 60,
      name: "primary"
    },
    {
      hue: 40,
      saturation: 20,
      brightness: 80,
      name: "bg"
    },
    {
      hue: 30,
      saturation: 20,
      brightness: 20,
      name: "text"
    }
  ]
});

class ColourPickerComponent extends React.Component<ColourPickerProps> {
  constructor(props: ColourPickerProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div>
        <ColourList store={this.props.store} />
        <Output store={this.props.store} />
      </div>
    );
  }
}

export default () => <ColourPickerComponent store={store} />;
