import * as React from "react";
import { ColourType } from "./state/Colour";
import { observer } from "mobx-react";
import Color from "color";

interface ColourChoiceProps {
  colour: ColourType;
}

const Suggestions: React.SFC<ColourChoiceProps> = ({ colour }) => {
  const { hue, saturation, brightness } = colour;

  const hues = [
    hue - 120,
    hue - 60,
    hue - 30,
    hue + 30,
    hue + 60,
    hue + 120,
    hue + 180
  ]
    .map(h => {
      if (h < 0) {
        return h + 360;
      } else if (h > 360) {
        return h - 360;
      } else {
        return h;
      }
    })
    .map(h => ({
      h,
      s: saturation,
      b: brightness
    }));

  const saturations = [
    saturation - 50,
    saturation - 40,
    saturation - 30,
    saturation - 20,
    saturation - 10,
    saturation + 10,
    saturation + 20,
    saturation + 30,
    saturation + 40,
    saturation + 50
  ]
    .filter(s => s >= 0 && s <= 100)
    .map(s => ({
      h: hue,
      s,
      b: brightness
    }));

  const brightnesses = [
    brightness - 50,
    brightness - 40,
    brightness - 30,
    brightness - 20,
    brightness - 10,
    brightness + 10,
    brightness + 20,
    brightness + 30,
    brightness + 40,
    brightness + 50
  ]
    .filter(s => s >= 0 && s <= 100)
    .map(b => ({
      h: hue,
      s: saturation,
      b
    }));

  const suggestions = hues.concat(saturations).concat(brightnesses);

  return (
    <div className="suggestions">
      {suggestions.map(({ h, s, b }, i) => (
        <div
          key={i}
          className="suggestion"
          style={{ backgroundColor: Color.hsl(h, s, b).string() }}
          role="button"
          onClick={() => colour.setHSL(h, s, b)}
        />
      ))}
    </div>
  );
};

export default observer(Suggestions);
