import * as React from "react";
import { observer } from "mobx-react";
import { StoreType, OutputFormat, outputFormats } from "./state/Store";
import SyntaxHighlighter from "react-syntax-highlighter";

export interface OutputProps {
  store: StoreType;
}

export default observer(
  class Output extends React.Component<OutputProps, any> {
    constructor(props: OutputProps) {
      super(props);
    }

    public render() {
      const format = this.props.store.format;
      const setFTo = (format: OutputFormat) => () =>
        this.props.store.setFormat(format);

      return (
        <div>
          <h2>Output</h2>
          <div className="suggestions">
            {this.props.store.colours.map((c, i) => (
              <div
                key={i}
                className="preview"
                style={{ backgroundColor: c.rgba }}
              />
            ))}
          </div>

          <br />
          {outputFormats.map((name, i) => (
            <a
              key={i}
              onClick={setFTo(name)}
              className={`tab${name === format ? " active" : ""}`}
            >
              {name}
            </a>
          ))}
          <SyntaxHighlighter language={format}>
            {this.props.store.output}
          </SyntaxHighlighter>
        </div>
      );
    }
  }
);
